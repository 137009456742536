.border-none[data-v-51761793]:before {
  display: none;
}
.border-none[data-v-51761793]:after {
  display: none;
}
.flex-fix[data-v-51761793] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-51761793]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-51761793] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-51761793] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-51761793] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-51761793] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-51761793] {
  *zoom: 1;
}
.clear-fix[data-v-51761793]:before,
.clear-fix[data-v-51761793]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.approval-page[data-v-51761793] {
  /* 列表为空*/
}
.approval-page .more[data-v-51761793] {
  overflow: hidden;
}
.approval-page header[data-v-51761793] {
  width: 100%;
  height: 0.88rem;
  background: #fff;
  padding: 0 0.48rem 0 0.6rem;
  font-size: 0.28rem;
  color: #999;
}
.approval-page header .tab-item[data-v-51761793] {
  height: 100%;
  line-height: 0.88rem;
  position: relative;
}
.approval-page header .select-item[data-v-51761793] {
  font-size: 0.28rem;
  color: var(--main-color);
}
.approval-page header .line[data-v-51761793] {
  position: absolute;
  width: 0.5rem;
  height: 0.04rem;
  border-radius: 0.06rem;
  background: var(--main-color);
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.approval-page .approval-list[data-v-51761793] {
  padding: 0.22rem 0.3rem;
}
.approval-page .approval-list .approval-item[data-v-51761793] {
  width: 100%;
  height: 2.9rem;
  padding: 0.2rem;
  background: #fff;
  border-radius: 0.16rem;
  margin-bottom: 0.2rem;
  position: relative;
  font-size: 0.28rem;
  color: #666;
}
.approval-page .approval-list .approval-item .top[data-v-51761793] {
  height: 0.56rem;
}
.approval-page .approval-list .approval-item .top .lh40[data-v-51761793] {
  line-height: 0.4rem;
}
.approval-page .approval-list .approval-item .top .status1[data-v-51761793] {
  font-size: 0.28rem;
  color: var(--main-color);
}
.approval-page .approval-list .approval-item .top .status2[data-v-51761793] {
  width: 1.38rem;
  height: 0.56rem;
  line-height: 0.56rem;
  text-align: center;
  font-size: 0.24rem;
  color: #999;
  border-radius: 0.28rem;
  border: 0.02rem solid #999;
}
.approval-page .approval-list .approval-item .middle[data-v-51761793] {
  font-size: 0.24rem;
  color: #999;
  height: 0.72rem;
}
.approval-page .approval-list .approval-item .middle .buyer-box[data-v-51761793] {
  padding-bottom: 2px;
}
.approval-page .approval-list .approval-item .middle .buyer-box div[data-v-51761793] {
  line-height: 0.34rem;
}
.approval-page .approval-list .approval-item .middle .price[data-v-51761793] {
  font-size: 0.36rem;
  color: #333;
}
.approval-page .approval-list .approval-item .goods-img[data-v-51761793] {
  width: 0.96rem;
  height: 0.96rem;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 0.2rem;
}
.approval-page .approval-list .approval-item .goods-img[data-v-51761793]:last-child {
  margin: 0;
}
.approval-page .approval-list .approval-item .img_more[data-v-51761793] {
  width: 0.7rem;
  height: 0.96rem;
  line-height: 0.96rem;
  text-align: center;
  border-radius: 0.04rem;
  font-size: 0.24rem;
  color: var(--main-color);
  margin-left: 0.2rem;
  background: #F3F2F5;
  position: absolute;
  right: 0.2rem;
}
.approval-page .empty-box[data-v-51761793] {
  height: calc(100vh - 0.88rem);
}
