.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.approval-page {
  /* 列表为空*/
}
.approval-page .more {
  overflow: hidden;
}
.approval-page header {
  width: 100%;
  height: 0.88rem;
  background: #fff;
  padding: 0 0.48rem 0 0.6rem;
  font-size: 0.28rem;
  color: #999;
}
.approval-page header .tab-item {
  height: 100%;
  line-height: 0.88rem;
  position: relative;
}
.approval-page header .select-item {
  font-size: 0.28rem;
  color: var(--main-color);
}
.approval-page header .line {
  position: absolute;
  width: 0.5rem;
  height: 0.04rem;
  border-radius: 0.06rem;
  background: var(--main-color);
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.approval-page .approval-list {
  padding: 0.22rem 0.3rem;
}
.approval-page .approval-list .approval-item {
  width: 100%;
  height: 2.9rem;
  padding: 0.2rem;
  background: #fff;
  border-radius: 0.16rem;
  margin-bottom: 0.2rem;
  position: relative;
  font-size: 0.28rem;
  color: #666;
}
.approval-page .approval-list .approval-item .top {
  height: 0.56rem;
}
.approval-page .approval-list .approval-item .top .lh40 {
  line-height: 0.4rem;
}
.approval-page .approval-list .approval-item .top .status1 {
  font-size: 0.28rem;
  color: var(--main-color);
}
.approval-page .approval-list .approval-item .top .status2 {
  width: 1.38rem;
  height: 0.56rem;
  line-height: 0.56rem;
  text-align: center;
  font-size: 0.24rem;
  color: #999;
  border-radius: 0.28rem;
  border: 0.02rem solid #999;
}
.approval-page .approval-list .approval-item .middle {
  font-size: 0.24rem;
  color: #999;
  height: 0.72rem;
}
.approval-page .approval-list .approval-item .middle .buyer-box {
  padding-bottom: 2px;
}
.approval-page .approval-list .approval-item .middle .buyer-box div {
  line-height: 0.34rem;
}
.approval-page .approval-list .approval-item .middle .price {
  font-size: 0.36rem;
  color: #333;
}
.approval-page .approval-list .approval-item .goods-img {
  width: 0.96rem;
  height: 0.96rem;
  object-fit: cover;
  margin-right: 0.2rem;
}
.approval-page .approval-list .approval-item .goods-img:last-child {
  margin: 0;
}
.approval-page .approval-list .approval-item .img_more {
  width: 0.7rem;
  height: 0.96rem;
  line-height: 0.96rem;
  text-align: center;
  border-radius: 0.04rem;
  font-size: 0.24rem;
  color: var(--main-color);
  margin-left: 0.2rem;
  background: #F3F2F5;
  position: absolute;
  right: 0.2rem;
}
.approval-page .empty-box {
  height: calc(100vh - 0.88rem);
}
